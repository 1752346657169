@font-face {
  font-family: "Barlow";
  src: url("Barlow-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("Metropolis-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow-light";
  src: url("Barlow-Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
  height: 700vh !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiToolbar-root {
  min-height: 86px !important;
  padding: 0px 24px 0px 24px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 600px) {
  .drawerHeader {
    min-height: 64px !important;
    padding: 0px 24px 0px 24px !important;
  }
  .MuiToolbar-root {
    min-height: 64px !important;
    padding: 0px 24px 0px 24px !important;
  }
}
@media (max-width: 600px) {
  .drawerHeader {
    min-height: 86px !important;
    padding: 0px 24px 0px 24px !important;
  }
  .MuiToolbar-root {
    min-height: 86px !important;
    padding: 0px 24px 0px 24px !important;
  }
}
.MuiAutocomplete-clearIndicator {
  display: none !important;
}